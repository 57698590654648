<template>
  <div>
    <v-card outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="subtitle-2 mb-4">
            <span v-if="organization.ssn !== null && typeof organization.ssn !== 'undefined'">{{ organization.ssn }} - </span>{{ organization.uuid }}
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ organization.label }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ organization.description }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            text
            outlined
            link
            target="_blank"
            :href="'https://admin.salescloud.is/en/company/' + organization.uuid + '/edit'"
          >
            {{ $t('manage') }}
          </v-btn>
        </v-list-item-action>

		<v-list-item-action class="pt-0 mt-0">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<div v-on="on">
						<v-btn
							color="success"
							class="my-4"
							width="135px"
							elevation="0"
							@click="triggerUpdateOrganization"
							:disabled="!updateOrganization && !organizationFormValid"
							:loading="updateOrganization"
						>
						{{ $t('save') }}
						</v-btn>
					</div>
				</template>
				{{ $t('missingRequiredValuesOrValuesAreIncorrect') }}
			</v-tooltip>
		</v-list-item-action>

      </v-list-item>

    </v-card>

	<!-- {{ organization }} -->

	<v-row>
      <v-col>
        <OrganizationForm 
          :value="organization"
          :trigger-update="updateOrganization"
          @finishedUpdating="updateOrganization = false"
          @valid="setOrganizationFormValidity"
          @updateProfile="updateProfile"
        />
      </v-col>
    </v-row>

    <!-- Only visible for SalesCloud employees when using SalesCloud ehf -->
    <v-card
      v-if="currentOrganizationUUID === 'f95bbe80-b9c2-40eb-943d-95d696c1acfb'"
      outlined
      class="mt-2"
    >
      <v-card-title>{{ $t('salesCloudProducts') }}</v-card-title>
      <v-card
        outlined
        class="mt-1 mx-3"
        style="border-color: rgba(0, 0, 0, 0.87)"
      >
        <v-card-text v-if="activeProducts.length > 0">
          <v-btn
            v-for="product in activeProducts"
            :key="product.key"
            depressed
            class="ma-1 pr-3"
            :ripple="false"
            label
          >
            {{ product.text }}
            <v-btn
              icon
              @click="removeItem(product.key)"
              @click.stop=""
              :ripple="false"
              :disable="updatingProducts || fetchingProducts"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-btn>
        </v-card-text>
        <v-card-text
          v-else-if="fetchingProducts"
          class="text-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="text-center"
        >
          <span> {{ errorMessage === '' ? $t('noSalesCloudProductsAssignedToThisOrg') : errorMessage }}</span>
        </v-card-text>
      </v-card>
      <v-card
        outlined
        color="transparent"
        class="mx-3"
      >
        <v-row>
          <v-col cols="4" />
          <v-col
            cols="4"
            class="text-left pr-0"
          >
            <v-autocomplete
              v-model="selectedItems"
              :items="availableProducts"
              return-object
              outlined
              hide-details
              style="margin-right: 15px; max-width: 460px"
              :label="$t('selectProducts')"
              multiple
              :error="productsError"
              :no-data-text="$t('noMoreProductsAvailable')"
            />
          </v-col>
          <v-col
            cols="1"
            class="pl-0 text-left"
          >
            <v-btn
              @click="addItems(selectedItems)"
              outlined
              height
              fab
              @mousedown.stop=""
              style="height: 56px;"
              :disabled="updatingProducts || !selectedItems || selectedItems.length === 0"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" />
        </v-row>
      </v-card>
    </v-card>



  </div>
</template>

<script>
import OrganizationForm from "../organization/OrganizationForm.vue"
export default {
    name: 'OrganizationProfile',
    props: {
      organization: {
        type: Object,
        required: true
      }
    },
	components: {
		OrganizationForm
	},
	data() {
		return {
			updateOrganization: false,
			organizationFormValid: false,

			selectedItems: null,
			activeProducts: [],
			productsError: false,
			updatingProducts: false,
			errorMessage: '',
			fetchingProducts: false,
			salesCloudProducts: [
				{
					key: 'etags',
					text: 'eTags',
				},
				{
					key: 'eventBooking',
					text: 'Event Booking',
				},
				{
					key: 'kds',
					text: 'KDS'
				},
				{
					key: 'kiosk',
					text: 'Kiosk',
				},
				{
					key: 'marketplace',
					text: 'Marketplace',
				},
				{
					key: 'menu',
					text: 'Menu'
				},
				{
					key: 'orderDisplay',
					text: 'Order Display',
				},
				{
					key: 'pos',
					text: 'POS',
				},
				{
					key: 'store',
					text: 'Store'
				},

				{
					key: 'subscriptions',
					text: 'Subscriptions',
				},
				{
					key: 'tableReservations',
					text: 'Table Reservations',
				},
				{
					key: 'yess',
					text: 'Yess',
				}
			]
		}
	},
		methods: {
			triggerUpdateOrganization() {
				this.updateOrganization = true
			},
			setOrganizationFormValidity(value) {
				this.organizationFormValid = value
			},
			queryActiveProducts() {
				this.fetchingProducts = true
				this.activeProducts = this.$store.dispatch('getActiveProducts', this.organization.uuid).then(res => {
					if(res) {
						this.activeProducts = res.map(product => {
							return this.salesCloudProducts.find(salesCloudProduct => salesCloudProduct.key === product)
						})
						this.sortProducts()
					}
				}).catch(() => {
					this.productsError = true
					this.activeProducts = []
					this.errorMessage = this.$t('errorFetchingProducts')
				}).finally(() => {
					this.fetchingProducts = false
				})
			},
			sortProducts() {
				this.activeProducts.sort((a, b) => a.key.localeCompare(b.key))
			},
			addItems(selectedProducts) {
				this.updatingProducts = true
				selectedProducts.forEach(product => {
					if(!this.activeProducts.includes(product)) {
						this.activeProducts.push(product)
					}
				})
				const updatedActiveProducts = this.activeProducts.map(product => product.key)
				this.sortProducts()
				this.$store.dispatch('updateOrganizationData', {uuid: this.organization.uuid, activeProducts: updatedActiveProducts}).then(res => {
					if(res) {
						this.$emit('updateOrganizationData', res)
					} else {
						this.productsError = true
						selectedProducts.forEach(selectedProduct => {
							const index = this.activeProducts.findIndex(product => product.key === selectedProduct.key)
							this.activeProducts.splice(index, 1)
						})
					}
				}).catch(() => {
					this.productsError = true
					this.activeProducts = []
					this.errorMessage = this.$t('errorUpdatingProducts')
				}).finally(() => {
					this.updatingProducts = false
				})
				this.selectedItems = null
			},
			removeItem(selectedProductKey) {
				this.updatingProducts = true
				const index = this.activeProducts.findIndex(product => product.key === selectedProductKey)
				if(index > -1) {
					const item = this.activeProducts.splice(index, 1)
					const updatedActiveProducts = this.activeProducts.map(product => product.key)
					this.$store.dispatch('updateOrganizationData', {uuid: this.organization.uuid, activeProducts: updatedActiveProducts}).then(res => {
						if(res) {
							this.$emit('updateOrganizationData', res)
						} else {
							this.activeItems.push(item)
							this.sortProducts()
						}
					}).catch(() => {
						this.productsError = true
						this.activeProducts = []
						this.errorMessage = this.$t('errorUpdatingProducts')
					}).finally(() => {
						this.updatingProducts = false
					})
				}
			},
			updateProfile(updatedValues) {
				for(const key in updatedValues) {
					this.organization[key] = updatedValues[key]
				}
			}
		},
		mounted() {
			this.queryActiveProducts()
		},
		computed: {
			currentOrganizationUUID() {
				return this.$store.getters.organization.uuid
			},	
			availableProducts() {
				if(this.activeProducts && Array.isArray(this.activeProducts) && this.activeProducts.length > 0) {
					return this.salesCloudProducts.filter(product => !this.activeProducts.includes(product))
				}
				return this.salesCloudProducts
			}
		},
		watch: {
			organization: {
				handler(newValue, oldValue) {
					if(newValue.uuid !== oldValue.uuid)
						this.queryActiveProducts()
				},
				deep: true
			}
		}
}
</script>
