<template>
    <v-form
      v-model="valid"
    >
      <v-expansion-panels
        mandatory
        flat
      >
        <v-expansion-panel
          v-for="(panel, i) in panels"
          :key="i"
        >
          <v-expansion-panel-header>{{ panel.title }}</v-expansion-panel-header>
  
          <v-expansion-panel-content>
            <v-row dense>
              <v-col
                :cols="field.cols || '12'"
                v-for="(field, index) in panel.fields"
                :key="index"
              >
                <v-text-field
                  v-if="field.type === 'text'"
                  dense
                  :label="field.required ? field.label + ' *' : field.label"
                  :type="field.type"
                  filled
                  :required="field.required"
                  v-model="value[field.key]"
                  :prepend-icon="field.icon"
                  :rules="field.rules"
                />
                <v-text-field
                  v-if="field.type === 'number'"
                  dense
                  :label="field.required ? field.label + ' *' : field.label"
                  :type="field.type"
                  filled
                  :required="field.required"
                  v-model="value[field.key]"
                  :prepend-icon="field.icon"
                  :rules="field.rules"
                />
  
                <v-checkbox
                  v-if="field.type === 'checkbox'"
                  dense
                  :label="field.required ? field.label + ' *' : field.label"
                  filled
                  :required="field.required"
                  v-model="value[field.key]"
                  :prepend-icon="field.icon"
                  :key="forceRenderKey"
                  :rules="field.rules"
                />
  
                <v-select
                  v-if="field.type === 'select'"
                  dense
                  :label="field.required ? field.label + ' *' : field.label"
                  filled
                  :required="field.required"
                  v-model="value[field.key]"
                  :items="field.options"
                  item-value="key"
                  item-text="label"
                  :prepend-icon="field.icon"
                  :rules="field.rules"
                  :multiple="field.multiple"
                />
  
                <v-autocomplete
                  v-if="field.type === 'autocomplete'"
                  dense
                  :label="field.required ? field.label + ' *' : field.label"
                  filled
                  :items="field.options"
                  item-text="label"
                  item-value="key"
                  :required="field.required"
                  v-model="value[field.key]"
                  :prepend-icon="field.icon"
                  :no-data-text="$t('couldNotFindValue')"
                  :rules="field.rules"
                />

              </v-col>
            </v-row>
          </v-expansion-panel-content>
  
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </template>
  
  <script>

  export default {
    name: 'OrganizationForm', 
    props: {
        value: {
            type: Object, 
            required: true, 
            default: () => {}
        }, 
        triggerUpdate: {
            type: Boolean, 
            default: false
        },
    },
    data() {
        return {
            valid: false
        }
    },
    computed: {
        currencies() {
            return this.$store.state.currencies.map(c => {
                return {
                    key: c.code,
                    label: c.name,
                }
            })
        },
        panels() {
            return [
                {
                    title: this.$t('defaultConfigurations'),
                    icon: 'mdi-domain',
                    fields: [

                    {
                        key: 'defaultTimezone',
                        label: this.$t('defaultTimezone'),
                        type: 'select',
                        required: true,
                        options: [
                            'UTC',
                            'Atlantic/Reykjavik',
                            'Europe/Stockholm',
                            'Europe/Copenhagen',
                            'Europe/London',
                            'Europe/Lisbon',
                        ], 
                        rules: [this.rules.required]
                    },
                    {
                        key: 'defaultCurrency', 
                        label: this.$t('defaultCurrency'), 
                        type: 'select', 
                        required: true, 
                        options: this.currencies,
                        rules: [this.rules.required]
                    }, 

                    ] 
                },
            ]
        },
         rules() {
            return this.$store.getters.rules
        }, 
    }, 
    watch: {
      triggerUpdate(value) {
        if(value === true) {
            const mutadedValues = {}
            // convert value to only include values defined in panels
            mutadedValues.uuid = this.value.uuid
            for (const panel of this.panels) {
                for (const x of panel.fields) {
                    mutadedValues[x.key] = this.value[x.key]
                }
            }

            return this.$store.dispatch('updateOrganizationData', mutadedValues).then(res => {
                if(res) {
                    this.$store.commit('updateActionSuccess', {
                        message: this.$t('success'), 
                        subMessage: this.$t('successfullyUpdatedOrganization')
                    })

                    this.$emit('updateProfile', res)

                } else {
                    this.$store.commit('updateActionError', {
                        message: this.$t('error'), 
                        subMessage: this.$t('somethingWentWrong')
                    })
                }
            }).catch(() => {
                this.$store.commit('updateActionError', {
                message: this.$t('error'), 
                subMessage: this.$t('somethingWentWrong')
                })
            }).finally(() => {

                this.$emit('finishedUpdating')
            })
        }
      }, 
      valid(value) {
          this.$emit('valid', value)
      }
    }
  }
  </script>
  
  <style>
        .v-expansion-panel {
        border: thin solid rgba(0, 0, 0, 0.12);
        border-radius: 8px
      }
  
      .v-expansion-panels {
        border-radius: 8px
      }
  </style>